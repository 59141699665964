<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!contract">
      <span class="loader"></span>
    </span>
    <div v-if="contract" class="elite-tabs-wrapper">
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        value="label"
      >
        <tab-pane
          v-if="contract.edition_url"
          title="label"
          id="1"
          :active="true"
        >
          <span slot="title">
            <svg
              width="16"
              height="15"
              viewBox="0 0 16 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.1026 13.449H2.68621C1.94547 13.449 1.3431 12.8466 1.3431 12.1059C1.3431 11.3652 1.9455 10.7628 2.68621 10.7628H4.02931C4.39999 10.7628 4.70086 10.462 4.70086 10.0913C4.70086 9.72059 4.39999 9.41972 4.02931 9.41972H2.68621C1.20476 9.41972 0 10.6245 0 12.1059C0 13.5874 1.20476 14.7921 2.68621 14.7921H14.1026C14.4733 14.7921 14.7742 14.4913 14.7742 14.1206C14.7742 13.7499 14.4733 13.449 14.1026 13.449Z"
                fill="#000"
              ></path>
              <path
                d="M15.2496 1.55846C14.2477 0.557847 12.6171 0.556503 11.6131 1.55846L10.5857 2.58594C10.5729 2.59669 10.5574 2.60072 10.5454 2.61281C10.5333 2.62491 10.5292 2.64034 10.5185 2.65309L7.07277 6.09882C6.40928 6.76365 6.04394 7.64742 6.04394 8.58693V10.0912C6.04394 10.4619 6.34481 10.7628 6.7155 10.7628H8.21978C9.1593 10.7628 10.043 10.3974 10.7092 9.73329L15.2489 5.19358C16.2502 4.19163 16.2502 2.56041 15.2496 1.55846ZM9.76032 8.78302C9.34867 9.19333 8.80134 9.41967 8.21978 9.41967H7.38705V8.58693C7.38705 8.00538 7.61336 7.45805 8.023 7.04705L11.0269 4.0432L12.7635 5.77982L9.76032 8.78302ZM14.2993 4.244L13.7131 4.83028L11.9764 3.09365L12.562 2.50806C13.0408 2.02992 13.8205 2.0306 14.2987 2.50806C14.7768 2.98687 14.7768 3.76586 14.2993 4.244Z"
                fill="#000"
              ></path>
            </svg>
            {{ $t("ESIGNS.EDITOR") }}
          </span>
          <package-editor :package-url="contract.edition_url" />
        </tab-pane>
        <tab-pane title="global" id="2" :active="true">
          <span slot="title">
            <i class="ni ni-cloud-upload-96"></i>
            {{ $t("COMMON.GLOBAL") }}
          </span>
          <contract-view-global
            :contract="contract"
            @onContractUpdated="get"
            @onEditContract="editContract"
            @onDeleteContract="deleteContract"
          />
        </tab-pane>

        <!-- <tab-pane
          title="files"
          id="3"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_CONTRACTFILES)"
        >
          <span slot="title">
            <i class="fa fa-file" aria-hidden="true"></i>
            {{ $t("CONTRACTS.FILES") }}
          </span>
          <div class="elite-tabs-wrapper-content">
            <list-contract-files-component :contract="contract" />
          </div>
        </tab-pane> -->

        <tab-pane
          title="logs"
          id="999"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
        >
          <span slot="title">
            <i class="fa fa-file"></i>
            {{ $t("COMMON.LOGS") }}
          </span>
          <contract-view-logs :contract="contract" />
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { Tabs, TabPane } from "@/components";
import ContractViewLogs from "../partials/ContractViewLogs.vue";
import ContractViewGlobal from "../partials/ContractViewGlobal.vue";
//import ListContractFilesComponent from "./files/ListContractFilesComponent.vue";
import EsignIcon from "@/components/Icons/EsignIcon.vue";
import PackageEditor from "@/components/PackageEditor.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    ContractViewLogs,
    ContractViewGlobal,
    //ListContractFilesComponent,
    EsignIcon,
    PackageEditor,
  },

  mixins: [],

  props: {
    contractId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      contract: null,
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch(
          "contracts/get",
          `${this.contractId}_include_edition_url`
        );
        this.contract = this.$store.getters["contracts/contract"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    editContract(contract) {
      this.$emit("onEditContract", contract);
    },
    deleteContract(contract) {
      this.$emit("onDeleteContract", contract);
    },
  },
};
</script>
